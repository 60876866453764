<template>
	<div id="home">


			<div class="swiper" style="width: 100vw;position: relative;left: -14vw;">
						<el-carousel height="4rem">
							<el-carousel-item style="width:100vw">
								<img style="width: 100%;height: 100%;" src="@/assets/images/banner/1.jpg" alt="">
							</el-carousel-item>
							<el-carousel-item style="width:100vw">
								<img style="width: 100%;height: 100%;" src="@/assets/images/banner/2.jpg" alt="">
							</el-carousel-item>
							<el-carousel-item style="width:100vw">
								<img style="width: 100%;height: 100%;" src="@/assets/images/banner/3.png" alt="">
							</el-carousel-item>
						</el-carousel>
					</div>
		<div class="home-box">
			<!-- 	<div class="home_top">
				<div class="swiper">
					<el-carousel height="2.25rem">
						<el-carousel-item>
							<img src="@/assets/images/banner/1.png" alt="">
						</el-carousel-item>
						<el-carousel-item>
							<img src="@/assets/images/banner/2.png" alt="">
						</el-carousel-item>
						<el-carousel-item>
							<img src="@/assets/images/banner/3.png" alt="">
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="Headlines-list">
					<div class="Headlines-item" v-for="(item,index) in Headlines" :key="index" @click="toWelfareInfo(item.id)">
						<div class="top">
							<img :src="item.user.avatar" alt="">
							<div class="describe">
								<div class="name">{{item.name | GuoLvOne}}</div>
								<p>本房间奖品总价值{{item.award_bean}}元</p>
							</div>
						</div>
						<div class="Roll_Goods">
							<div class="Roll_Goods_item" v-for="(item,index) in item.box_records" :key="index" :style="item.lv | StyChoice">
								<div class="pic" :style="{'background-image': 'url('+item.lv_bgImage+')'}">
									<img :src="item.cover" alt="">
								</div>
							</div>
						</div>
						<div class="bottom">
							<div class="countDown">
								<van-count-down :time="new Date(item.end_time).getTime() - new Date().getTime()" format="DD 天 HH 时 mm 分">
									<template #default="timeData">
										<span class="block" v-text="timeData.days < 10 ? '0'+timeData.days : timeData.days"></span>
										<span class="colon">:</span>
										<span class="block" v-text="timeData.hours < 10 ? '0'+timeData.hours : timeData.hours"></span>
										<span class="colon">:</span>
										<span class="block" v-text="timeData.minutes < 10 ? '0'+timeData.minutes : timeData.minutes"></span>
									</template>
								</van-count-down>
							</div>
						</div>
					</div>
				</div>
			</div> -->

			<div class="Roll d-none">
				<div class="Roll_item" v-for="(item,index) in Headlines" :key="index" @click="toWelfareInfo(item.id)">
					<div class="Roll_left">
						<p class="Roll_tit">{{item.name | GuoLvOne}}</p>
						<span class="JG">账户至少充值<img src="@/assets/images/PublicImg/JinBi.png" alt="">{{item.min_recharge}}</span>
						<div class="DJS">
							<van-count-down :time="new Date(item.end_time).getTime() - new Date().getTime()" format="DD 天 HH 时 mm 分">
								<template #default="timeData">
									<span class="block" v-text="timeData.days < 10 ? '0'+timeData.days : timeData.days"></span>
									<span class="colon">:</span>
									<span class="block" v-text="timeData.hours < 10 ? '0'+timeData.hours : timeData.hours"></span>
									<span class="colon">:</span>
									<span class="block" v-text="timeData.minutes < 10 ? '0'+timeData.minutes : timeData.minutes"></span>
								</template>
							</van-count-down>
						</div>
					</div>
					<div class="Roll_right">
						<div class="Roll_pic">
							<img :src="item.box_records[0].cover" alt="">
						</div>
						<p><img src="@/assets/images/PublicImg/JinBi.png" alt="">{{item.award_bean}}</p>
					</div>
				</div>
			</div>

			<div class="home-List-box">

				<div v-for="(box,index) in HotList" :key="index + 'a'">
						<div class="Title">
							<p>{{box.name}}</p>
						</div>
						<div class="home-List">
							<div class="home-list-item" v-for="(item,index) in box.box" :key="index" @click="ToOpen(item.id)">
								<div class="pic"  >
									<img v-lazy="item.cover">
								</div>
								<div class="weapon_cover" style="z-index: 99;position: relative;">
									<img :src="item.weapon_cover" alt="">
								</div>
								<p>{{item.name}}</p>
								<div class="Blind_btn">
									<p>打开箱子</p><span><img src="@/assets/images/PublicImg/JinBi.png" alt="">{{item.bean}}</span>
								</div>
							</div>
						</div>
				</div>
			</div>

		</div>
	</div>

</template>

<script>
	import {
		mapState,
		mapMutations
	} from 'vuex'
	import {
		GetHotBox,
		Rooms,
		PersonalInfo
	} from '@/network/api.js'
	export default {
		name: 'Home',
		data() {
			return {

				HotList: [],
				BladeList: [],
				recruitList: [],
				classicList: [],
				vitalityList: [],

				hotboxlist: [],
				time: 10000,
				Headlines: [],
				soulList:[],
				XyList:[]
			}
		},


		created() {
			this.ObtainHotBox()
			this.GetRooms()
		},



		filters: {
			GuoLvOne(val) {
				if (val.length > 14) {
					return val.substr(0, 14) + '...'
				} else {
					return val
				}
			},
			StyChoice(val) {
				console.log(val)
				if (val == '1') {
					// return 'border-bottom-color:#e6ba1c;background-image: linear-gradient(to top, rgba(230, 186, 28,.5), transparent)'
					return 'background: url(' + require('@/assets/images/Roll/back_huang.png') +') no-repeat center bottom;background-size: contain;'
				} else if (val == '2') {
					// return 'border-bottom-color:#d32ce6;background-image: linear-gradient(to top, rgba(211, 44, 230,.5), transparent)'
					return 'background: url(' + require('@/assets/images/Roll/back_fen.png') +') no-repeat center bottom;background-size: contain;'
				} else if (val == '3') {
					// return 'border-bottom-color:#834fff;background-image: linear-gradient(to top, rgba(131, 79, 255,.5), transparent)'
					return 'background: url(' + require('@/assets/images/Roll/back_lan.png') +') no-repeat center bottom;background-size: contain;'
				} else if (val == '4') {
					// return 'border-bottom-color:#4b69ff;background-image: linear-gradient(to top, rgba(75, 105, 255,.5), transparent)'
					return 'background: url(' + require('@/assets/images/Roll/back_hui.png') +') no-repeat center bottom;background-size: contain;'
				} else {
					// return 'border-bottom-color:#bbbbbb;background-image: linear-gradient(to top, rgba(187, 187, 187,.5), transparent)'
					return 'background: url(' + require('@/assets/images/Roll/back_hui.png') +') no-repeat center bottom;background-size: contain;'
				}
			}
		},

		methods: {

			//热门宝箱
			ObtainHotBox() {
				GetHotBox().then((res) => {
					// console.log(res.data.data)
					this.HotList = res.data.data
					// this.BladeList = res.data.data.hotboxlist.slice(5, 20)
					// this.recruitList = res.data.data.hotboxlist.slice(20, 30)
					// this.classicList = res.data.data.hotboxlist.slice(30, 40)
					// // this.vitalityList = res.data.data.hotboxlist.slice(35,40 )
					// this.soulList = res.data.data.hotboxlist.slice(40,45)
					// this.XyList = res.data.data.hotboxlist.slice(45,50)
				})
			},
			//开箱
			ToOpen(id) {
				this.$router.push({
					path: '/OpeningBox',
					query: {
						id: id
					}
				})
			},

			//房间
			GetRooms() {
				Rooms('1').then((res) => {

					let RoomList = res.data.data.data
					// console.log(RoomList)
					// function compare(property) {
					// 	return function(a, b) {
					// 		let value1 = Number(a[property]);
					// 		let value2 = Number(b[property]);
					// 		return value1 - value2
					// 	}
					// }
					let haveHand = RoomList.filter((item) => {
						return item.status_alias == '进行中'
					})
					// console.log(haveHand)
					this.Headlines = haveHand.slice(0, 3)
					// console.log(this.Headlines)

				})
			},

			//前往房间
			toWelfareInfo(id) {
				this.$router.push({
					path: '/room',
					query: {
						id: id
					}
				})
			},

			...mapMutations([
				'User',
				'GetIndividualsShow',
				'GetRegisterShow',
				'GetSignInShow'
			])
		},

		computed: {
			...mapState([
				'user',
				'IndividualsShow',
				'RegisterShow',
				'SignInShow'
			])
		}

	}
</script>
<style lang="scss" scoped>
	.home-box {
		width: 16rem;
		margin-left: -1rem;
		padding-top: 0.4rem;
		font-family: "Times New Roman";
		font-weight: bold;
		.Roll{
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin-top: 50px;
			.Roll_item{
				width: 4.45rem;
				height: 2.3rem;
				border-radius: .1rem;
				background:url(../../assets/images/Roll/itemback1.png) no-repeat center;
				background-size: 100% 100%;
				padding: .2rem;
				box-sizing: border-box;
				display: flex;
				justify-content: space-between;
				cursor: pointer;
				transition: .3s;
				&:hover{
					transform: translateY(-.1rem);
					box-shadow: #E9B10E 0rem 0rem 0.05rem;
				}
				&:nth-child(2){
					background:url(../../assets/images/Roll/itemback2.png) no-repeat center;
					background-size: 100% 100%;
				}
				&:nth-child(3){
					background:url(../../assets/images/Roll/itemback3.png) no-repeat center;
					background-size: 100% 100%;
				}
				.Roll_left{
					width: 1.9rem;
					height: 100%;
					position: relative;
					.Roll_tit{
						font-size: .21rem;
						margin-top: .1rem;
					}
					.JG{
						display: block;
						text-align: right;
						font-size: .17rem;
						margin-top: .3rem;
						padding: 0 .01rem;
						background: red;
						height: .4rem;
						line-height: .4rem;
						text-align: center;
						background: rgba($color: #2d384e, $alpha: .3);
						border-radius: .04rem;
						img{
							vertical-align: middle;
							width: .25rem;
						}
					}

					.DJS{
						position: absolute;
						left: -.33rem;
						top: 1.25rem;
						width: 2.23rem;
						height: .7rem;
						background: url(../../assets/images/Roll/DJSback.png) no-repeat center;
						background-size:100% 80%;
						box-sizing: border-box;
						.van-count-down{
							height: 100%;
							display: flex;
							padding-top: .17rem;
							justify-content: center;
							.colon {
							   display: inline-block;
							   margin: 0 4px;
							   color: #fff;
							 }
							 .block {
							   display: inline-block;
							   width: 22px;
							   color: #fff;
							   font-size: .22rem;
							   text-align: center;
							 }
						}

					}

				}

				.Roll_right{
					width: 2rem;
					height: 100%;
					position: relative;
					.Roll_pic{
						width: 1.5rem;
						height: 1.5rem;
						display: flex;
						justify-content: center;
						align-items: center;
						img{
							max-width: 100%;
							max-height: 100%;
						}
					}
					p{
						position: absolute;
						right: -.1rem;
						bottom: .1rem;
						width: 1.3rem;
						height: .4rem;
						background: #212936;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: .06rem;
						img{
							width: .25rem;
							height: .25rem;
							vertical-align: middle;
						}
					}
				}
			}
		}
		.home_top {
			width: 100%;
			height: 2.25rem;
			display: flex;
			justify-content: space-between;

			.swiper {
				width: 4.8rem;
				height: 2.25rem;
				border-radius: 6px;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					vertical-align: middle;
				}
			}

			.Headlines-list {
				width: 100%;
				display: flex;
				justify-content: space-between;

				.Headlines-item {
					width: 3.6rem;
					height: 2.25rem;
					cursor: pointer;
					background: url(../../assets/images/public/back_pub.jpg) no-repeat;
					background-size: cover;
					border-radius: 0.1rem;
					padding: 0.1rem;
					box-sizing: border-box;
					position: relative;

					.top {
						display: flex;
						align-items: center;

						img {
							width: .5rem;
							height: .5rem;
							border-radius: 50%;
							margin-right: .1rem;
						}

						.describe {
							.name {
								font-size: .16rem;
								font-weight: bold;
							}

							p {
								font-size: .14rem;
							}
						}
					}

					.Roll_Goods {
						width: 100%;
						height: .8rem;
						margin-top: 10px;
						display: flex;
						box-sizing: border-box;
						justify-content: space-around;
						flex-wrap: wrap;
						overflow: hidden;

						.Roll_Goods_item {
							width: 30%;
							height: .8rem;
							border-bottom: 2px solid transparent;
							box-sizing: border-box;

							.pic {
								width: 90%;
								height: 90%;
								margin: .02rem auto 0;
								display: flex;
								justify-content: center;
								align-items: center;
								background-position: center;
								background-repeat: no-repeat;
								background-size: contain;

								img {
									max-width: 90%;
									max-width: 90%;
								}
							}
						}
					}

					.countDown {
						margin-top: -.15rem;
						text-align: center;

						.colon {
							display: inline-block;
							width: 0.4rem;
							height: 0.3rem;
							text-align: center;
							color: #fff;
							font-size: 0.44rem;
							line-height: 1rem;
						}

						.block {
							display: inline-block;
							width: 0.6rem;
							height: 0.4rem;
							background: rgba(48, 57, 81, 0.8);
							border-top: 1px solid #49587e;
							color: #fff;
							font-size: 0.22rem;
							line-height: 0.4rem;
							letter-spacing: 0.4em;
							padding-left: 0.08rem;
							text-align: center;
							background-size: cover;
							box-sizing: border-box;
							border-radius: 0.04rem;
						}
					}
				}
			}


		}


		.home-List-box {
			.Title {
				width: 19.20rem;

				margin-left: -1.6rem;
				padding: .1rem;
			}

			.home-List {
				width: 100%;
				display: flex;
				flex-wrap: wrap;

				.home-list-item {
					width: 20%;
					cursor: pointer;
					margin: 0.4rem 0 0.25rem;
					position: relative;
					padding-bottom:20px ;
					.weapon_cover {
						width: 2.5rem;
						height: 3rem;
						margin: 0 auto;
						display: flex;
						justify-content: center;
						align-items: center;

						img {
							max-width: 100%;
							max-height: 100%;
							transition: .3s;
						}
					}

					.pic {
						//
						position: absolute;
						left: 0.35rem;
						top: 0rem;
						display: flex;
						justify-content: center;
						align-items: center;
						z-index: 10;

						img {
							max-width: 100%;
							max-height: 100%;
							transition: .3s;
						}
					}

					p {
						height: 20px;
						font-size: 0.18rem;
						line-height: 25px;
						color: #FFF;
						text-align: center;
					}

					.Blind_btn {
						width: 150px;
						height: 34px;
						margin: 10px auto 0;
						// border: 1px solid #ffba2e;
						// background-image: linear-gradient(#ffcf00,#ffb500);
						/*border-radius: 8px;*/
						display: flex;
						justify-content: center;
						align-items: center;
position: relative;
							z-index: 99;

						p {
							display: none;
							width: 50%;
							height: 34px;
							text-align: center;
							line-height: 34px;
							font-size: 16px;
							/*font-weight: 550;*/
							color: #fff;
							position: relative;
							z-index: 99;
						}

						span {
							width: 50%;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 18px;
							color: #fff;

							img {
								margin-right: 10px;
								width: 26px;
							}
						}
					}
position: relative;
&::before{
							content: "";
							position: absolute;
							width: 100%;
							height: 100%;
						background-size: 100%;
						}
					&:hover {
						&::before{
							width:3.5rem;
							height: 3.5rem;
							animation: zhuan 10s linear infinite;
							background-repeat: no-repeat;
							background-image: url('../../assets/images/OpenBox/pic-bg.png');
						}
						.weapon_cover {
							img {
								transform: translateY(-20px);
							}
						}
						.Blind_btn{
							// box-shadow: #ffba2e 0px 0px 10px 1px;
							position: relative;
							z-index: 99;
							background-image: linear-gradient(#ffcf00,#ffb500);
							span{
								display: none;
								color: #000;
							}
							p{
								display: block;
								color: #000;
							}
						}
					}
				}
			}
		}

	}


	@keyframes zhuan {
		to{
			transform: rotate(1turn);
		}
	}
</style>
